<template>
	<MhView :isLoading="isLoading">				

		<div class="row">			
			<div class="col col--4of12">
				mode: img<br />
				takes the height the image needs
				<MhImage class="first" :imageObject="image" :debug="false"></MhImage>				
			</div>
			<div class="col col--4of12">
				mode: cover<br />
				height needs to be set
				<MhImage class="second" :imageObject="image" mode="cover"></MhImage>
			</div>
			<div class="col col--4of12">
				mode: contain<br />
				height needs to be set
				<MhImage class="third" :imageObject="image" mode="contain"></MhImage>
			</div>
		</div>
		<div class="row">			
			<div class="col">
				mode: img<br />
				takes the height the image needs
				<MhImage class="first" :imageObject="image" :debug="false"></MhImage>				
			</div>
		</div>
		
	</MhView>
</template>

<script>
	// @ is an alias to /src
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhView from '@/components/MhView/MhView.vue'

	export default {
		name: 'mhImageView',
		components: {
			MhView,
			MhImage,
		},
		data() {
			return {
				image: {					
					ID: 77,
					id: 77,
					title: "dummy-2160x2880-Mermaid",
					filename: "dummy-2160x2880-mermaid.jpg",
					filesize: 1118732,
					url: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid.jpg",
					link: "http://ies.local:8080/de/de/projects/projekt-1/dummy-2160x2880-mermaid/",
					alt: "",
					author: "1",
					description: "",
					caption: "",
					name: "dummy-2160x2880-mermaid",
					status: "inherit",
					uploaded_to: 204,
					date: "2018-09-18 16:09:47",
					modified: "2018-09-21 12:28:03",
					menu_order: 0,
					mime_type: "image/jpeg",
					type: "image",
					subtype: "jpeg",
					icon: "http://ies.local:8080/wp-includes/images/media/default.png",
					width: 2160,
					height: 2880,
					sizes: {
						thumbnail: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-100x100.jpg",
						'thumbnail-width': 100,
						'thumbnail-height': 100,
						medium: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-800x1067.jpg",
						'medium-width': 800,
						'medium-height': 1067,
						medium_large: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-768x1024.jpg",
						'medium_large-width': 768,
						'medium_large-height': 1024,
						large: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-1200x1600.jpg",
						'large-width': 1200,
						'large-height': 1600,
						icon: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-50x50.jpg",
						'icon-width': 50,
						'icon-height': 50,
						small: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-400x533.jpg",
						'small-width': 400,
						'small-height': 533,
						huge: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-2000x2667.jpg",
						'huge-width': 2000,
						'huge-height': 2667,
						full: "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid.jpg",
						'full-width': 2160,
						'full-height': 2880
					}
				},
				isLoading: true,
			}
		},
		mounted() {
			setTimeout( () => {
				this.isLoading = false
			}, 1000)
		},
	}
</script>

<style lang="less" scoped>
	.view {
		padding: 1em;
	}

	.mhImage {
		outline: 1px solid red;
		background-color: fade(red, 25);
	}
	.first {
	}
	.second {
		height: 200px;
	}
	.third {
		height: 200px;
	}
</style>
